<template>
  <!-- header-fixed为页面下滑后导航样式 -->
  <div class="header header-fixed">
    <div class="logo"><a></a></div>
    <!-- in为小屏（<990px）滑出样式 -->
      <div id="menu" :class="menuClass">
      <ul>
        <!-- menu-item-active 为显示样式 -->
        <li
          :class="getMenuItemClass(item.id)"
          v-for="item in homePageSetting.navigationList"
          :key="item.id"
        >
          <div class="menu-stair">
            <a
              href="/#"
              @click.prevent="routerLink(item.routeUrl)"
              @mouseenter="navigationEnter(item.id)"
              class="menu-item-name"
              >{{ item.title }}</a
            >
            <!-- 小屏点击箭头显示menu-horizontal-active -->
            <span class="menu-arr-right"
            @click.prevent="navigationEnter(item.id)"
            ></span>
          </div>
          <!-- menu-horizontal-active为大屏（>990px）鼠标经过 menu-item-name 的样式 -->
          <div
            :class="getMenuHorizontalClass(item.id)"
            @mouseenter="navigationEnter(item.id)"
            @mouseleave="navigationLeave()"
            v-if="item.children && item.children.length > 0"
          >
            <a
              class="menu-horizontal-item"
              href="#"
              v-for="subItem in item.children"
              :key="subItem.id"
              @click.prevent="routerLink(subItem.routeUrl)"
              >{{ subItem.title }}</a
            >
          </div>
        </li>
      </ul>
    </div>
      <el-collapse v-model="activeNames" accordion class="search">
        <el-collapse-item name="search" >
        <template slot="title">
          <i class="header-icon el-icon-info"></i>
        </template>
        <!-- 大屏显示 -->
        <div class="search-box">
          <div class="search-title">搜索资讯/文章</div>
          <el-form :inline="true" :model="searchModel" class="demo-form-inline">
            <el-form-item label="">
              <el-input v-model="searchModel.title" placeholder=""></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"  @click="doSearchHandler">搜索</el-button>
            </el-form-item>
          </el-form>
            <div class="search-close" @click="searchCloseHandler"></div>
        </div>
        <!-- 小屏显示 -->
        <div class="m-search-box">
          <el-form
            :inline="true"
            :model="searchModel"
            class="demo-form-inline m-search-box-line"
          >
            <el-form-item label="">
              <el-input v-model="searchModel.title" placeholder=""></el-input>
            </el-form-item>
              <div class="m-search-box-close" @click="searchCloseHandler"></div>
          </el-form>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="telephone">{{ homePageSetting.telephone }}</div>
    <div class="m-header">
        <div class="m-menu"
              @click="menuClassClickHandler"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfficialOtherHead",
  props: {
    homePageSetting: {
      type: Object,
    },
  },
  data: function () {
    return {
      menuClass: [],
      //搜索框折叠激活选项名
      activeNames:[],
      //当前鼠标悬停的路由id
      navigationHoverId: undefined,
      //查询参数
      searchModel: {
        title: undefined,
      },
      //查询结果
      searchResultArr: [],
    };
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop < 100) {
        this.navigationHeaderClass = ["header"];
      } else {
        this.navigationHeaderClass = ["header", "header-fixed"];
      }
    },
    routerLink(url) {
      //顶部导航栏跳转
      if (!url) {
        return false;
      }
      //网站内路由跳转
      if (url.indexOf("/") === 0) {
        this.$router.push({ path: url });
        if ('/'!==this.$route.path&&url.indexOf(this.$route.path) === 0) {
          window.location.reload();
        }
      } else if (url.indexOf("http") === 0) {
        //外站连接
        window.open(url, "_self");
      }
      return false;
    },
    navigationEnter(id) {
      //鼠标移入路由
      this.navigationHoverId = id;
    },
    navigationLeave() {
      //鼠标离开路由
      this.navigationHoverId = undefined;
    },
    getMenuItemClass(id) {
      //获取导航栏目样式
      if (id && this.navigationHoverId === id) {
        return ["menu-item", "menu-item-active"];
      }
      return ["menu-item"];
    },
    getMenuHorizontalClass(id) {
      //获取导航栏目二层样式
      if (id && this.navigationHoverId === id) {
        return ["menu-horizontal", "menu-horizontal-active"];
      }
      return ["menu-horizontal"];
    },
    searchCloseHandler(){
      this.activeNames = []
    },
    menuClassClickHandler(){
      if(this.menuClass.length<1){
        this.menuClass = ['in']
      }else{
        this.menuClass = []
      }
    },
    doSearchHandler(){
      this.activeNames = []
      this.$router.push({ path: "/search?search=" + this.searchModel.title });
    },
  },
};
</script>

<style scoped>
#app {
  background: #f4f6f8;
}
.main {
  margin: 88px auto 0;
  width: 100%;
}
.content-title {
  max-width: 1920px;
  position: relative;
  height: 360px;
  overflow: hidden;
}
.hidden-xs {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-mx {
  display: none;
}
.content-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 0 68px;
}
.content-box h1,
.content-box div {
  text-align: center;
  color: #fff;
}
.content-box h1 {
  font-size: 40px;
  line-height: 52px;
}
.content-box div {
  margin: 13px 0 0;
  font-size: 16px;
  line-height: 22px;
}
.panel {
  position: relative;
  z-index: 999;
  margin: -68px auto 0;
  padding: 0 0 68px;
  width: 100%;
}
.container {
  margin: 0 auto;
  padding: 65px 100px;
  max-width: 1200px;
  background: #fff;
}
.content {
  width: 100%;
}
.content p {
  margin: 0 auto 20px;
  font-size: 16px;
  line-height: 36px;
}
.content img {
  margin: 0 auto 16px;
  display: block;
  max-width: 100%;
  height: auto;
}
.content span {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 36px;
  color: rgb(0 0 0 /50%);
}
.share {
  position: relative;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
}
.share:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background: #eee;
}
.share-item {
  position: relative;
  z-index: 2;
  padding: 0 17px;
  display: inline-block;
  height: 32px;
  background: #fff;
}
.share-item i {
  display: block;
  width: 32px;
  height: 32px;
  opacity: 0.64;
}
.wecaht i {
  background: url(../assets/images/share-wechat.png) no-repeat center/32px;
}
.weibo i {
  background: url(../assets/images/share-weibo.png) no-repeat center/32px;
}
.friend i {
  background: url(../assets/images/share-friend.png) no-repeat center/32px;
}
.link-box {
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  padding: 10px 0;
  width: 100%;
}
.link-box-item {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
}
.link-box-item span {
  display: inline-block;
  padding: 0 6px 0 0;
  font-size: 18px;
  line-height: 36px;
}
.link-box-item a {
  font-size: 18px;
  line-height: 36px;
}
.footer {
  margin: 0 auto;
}

@media (max-width: 1400px) {
  .container {
    max-width: auto;
    width: 90%;
  }
}

@media (max-width: 990px) {
  .main {
    margin: 64px auto 0;
  }
  .hidden-xs {
    display: none;
  }
  .hidden-mx {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .panel {
    padding: 0 0 40px;
  }
  .container {
    padding: 30px 50px;
  }
}

@media (max-width: 640px) {
  #app {
    background: #fff;
  }
  .content-title {
    height: 5.6rem;
  }
  .content-title img {
    width: 100%;
    height: 5.6rem;
    object-fit: cover;
  }
  .content-box {
    padding: 0.5rem 0 1.92rem;
  }
  .content-box h1,
  .content-box div {
    padding: 0 0.88rem;
  }
  .content-box h1 {
    font-size: 0.64rem;
    height: 0.84rem;
  }
  .content-box div {
    font-size: 0.28rem;
    line-height: 0.4rem;
  }
  .panel {
    margin: -1.92rem 0 0;
    padding: 0;
  }
  .container {
    margin: 0 0.48rem;
    padding: 0.46rem 0.4rem;
    width: calc(100% - 0.96rem);
  }
  .content p {
    margin: 0 auto 0.3rem;
    font-size: 0.36rem;
    line-height: 0.64rem;
  }
  .content img {
    margin: 0 auto 0.2rem;
  }
  .content span {
    font-size: 0.14rem;
    line-height: 0.48rem;
    color: #414141;
  }
  .share-item {
    padding: 0 0.34rem;
    height: 0.64rem;
  }
  .share-item i {
    display: block;
    width: 0.64rem;
    height: 0.64rem;
  }
  .wecaht i {
    background: url(../assets/images/share-wechat.png) no-repeat center/.64rem;
  }
  .weibo i {
    background: url(../assets/images/share-weibo@2x.png) no-repeat center/.64rem;
  }
  .friend i {
    background: url(../assets/images/share-friend@2x.png) no-repeat
      center/.64rem;
  }
  .link-box {
    display: none;
  }
}
</style>
